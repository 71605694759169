<script setup lang="ts">
	import PageHeader from "@/components/Navigation/PageHeader.vue";
	import ListKeys from "@/components/KeyManagement/ListKeys.vue";
	import { onMounted, ref } from "vue";
	import { getCurrentUser } from "aws-amplify/auth";
	import { AsyncDialog } from "@jca/libs/confirm";
	import AddKey from "@/components/KeyManagement/AddKey.vue";
	import { usePortalServicesHttp } from "@/composables/portal-services-api/http-portal-services";
	import { useAddKeyMutation, useDeleteKeyMutation } from "@/composables/portal-services-api/portal-services";
    import { Key } from "@/composables/portal-services-api-models";
    import { ConfirmDialog } from "@jca/libs/confirm";

	const addDialog = ref<InstanceType<typeof AsyncDialog>>();
    const confirmDialog = ref<InstanceType<typeof ConfirmDialog> | null>(null);

	const email = ref("");

	onMounted(async () => {
		const { username } = await getCurrentUser();
		email.value = username;
	});

	const portalServicesHttp = usePortalServicesHttp();
	
    //Mutations
	const addKeyMutation = useAddKeyMutation(portalServicesHttp);
    const deleteKeyMutation = useDeleteKeyMutation(portalServicesHttp);


	function addKey() {
		addKeyMutation.reset();
		addDialog.value?.open<void, { publicKey: string }>({
			callback: async result => {
				try {
                    console.log("result.publicKey", result.publicKey);
					const response = await addKeyMutation.mutateAsync({
						email: email.value,
						key: btoa(result.publicKey),
					});
					return true;
				} catch (error) {
					console.error(error);
					return false;
				}
			},
		});
	}

    async function deleteKey(key: Key) {
		var okText = "Delete";
		var msg = `Are you sure you want to delete this key? ${key.publicKey}`;
		const result = await confirmDialog.value?.open({
			title: `Confirm ${okText}`,
			text: msg,
			okText: okText,
			waitOnConfirm: true,
		});

		if (!result) return;

		await deleteKeyMutation.mutateAsync({
            email: key.email?.toString() ?? "",
			key: key.publicKey ? btoa(key.publicKey.toString()) : "",
		});
		result();
	}


</script>

<template>
	<PageHeader
		title="My SSH Keys"
		:back="false"
	/>
	<div class="box-border flex min-h-0 flex-col gap-4 pl-5">
		<ListKeys :email="email"
        @add="addKey"
        @delete="deleteKey"
         />
	</div>

	<AsyncDialog
		ref="addDialog"
		v-slot="slotProps"
	>
		<AddKey
			:loading="slotProps.loading"
			:email="email"
			@close="slotProps.close($event)"
			:error="addKeyMutation.error.value?.toString()"
		/>
	</AsyncDialog>
    <ConfirmDialog ref="confirmDialog" class="keyDialog"/>

</template>

