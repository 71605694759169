<script setup lang="ts">
    import ClockSpinnerIcon from "@/icons/ClockSpinnerIcon.vue";
    import { useValidation } from "@jca/libs/forms";
	import { DialogLayout } from "@jca/libs/ui";
    import { reactive } from "vue";
    import { z } from "zod";
    
    const props = defineProps<{
        loading: boolean;
        email: string;
        error?: string;
    }>();
    const emit = defineEmits<{
        close: [{ publicKey: string }?];
    }>();

	const keySchema = z.object({
		publicKey: z
			.string()
			.min(11, "Please enter a valid ssh-ed25519 public key")
			
	});

	const result = reactive({
		publicKey: "",
	});

	const validation = useValidation(result, keySchema);

	async function save() {
		if (validation.validate()) emit("close", result);
	}

</script>
<template>
	<DialogLayout class="w-[20rem] bg-netural-orange">
		<template #title>
			<span class="font-bold text-dark-green">Add Public Key</span>
		</template>

        <textarea class="w-full h-32" v-model="result.publicKey">

        </textarea>
        <div v-if="error" class="text-error text-left">{{ error }}</div>
        <div v-if="validation.errors.publicKey" class="text-error text-left">{{ validation.errors.publicKey }}</div>

		<template #actions>
			<button
				class="button bg-light-orange"
				:disabled="props.loading"
				@click="emit('close')"
			>
				Cancel
			</button>
			<button
				class="button bg-bright-orange justify-end flex items-center"
				:disabled="props.loading"
				@click="save"
			>
				<ClockSpinnerIcon v-if="loading" />
				<span>Save</span>
			</button>
		</template>
	</DialogLayout>
</template>
