<script setup lang="ts">
	import DataTable from "primevue/datatable";
	import type { DataTableFilterMetaData } from "primevue/datatable";
	import Column from "primevue/column";
	import Search from "@/components/Search.vue";
	import { computed, ref } from "vue";
	import { usePortalServicesHttp } from "@/composables/portal-services-api/http-portal-services";
	import { useGetKeysByEmail } from "@/composables/portal-services-api/portal-services";
	import type { Key } from "@/composables/portal-services-api-models";
	import Loading from "@/components/Loading.vue";

	const props = defineProps<{
		email: string;
	}>();

	const emit = defineEmits<{
		add: [];
		delete: [key: Key];
	}>();

	const filters = ref<{ global: DataTableFilterMetaData }>({
		global: {
			value: null,
			matchMode: "contains",
		},
	});
	const portalServicesHttp = usePortalServicesHttp();

	const { data: keys, isLoading: loading } = useGetKeysByEmail(
        portalServicesHttp,
        computed(() => props.email),
        computed(() => props.email !== "" && props.email !== undefined),
    );


	const cleanKey = (key: string) => {
		var value = key.split(" ");
		return value[0] + " " + value[1];
	};

	const label = (key: string) => {
		var value = key.split(" ");
		if (value.length < 3) {
			return "";
		}
		return value[2];
	};
</script>

<template>
	<div class="box-border flex min-h-0 flex-col gap-4 p-4">
		<div
			class="customizedPrimeVueTable keyTable flex grid grid-cols-4 flex-col gap-2 gap-4 overflow-y-auto"
		>
			<DataTable
				tableStyle="min-width: 100%; min-height: 100%;"
				paginator
				:rows="5"
				:filters="filters"
				:value="keys"
				dataKey="publicKey"
				:loading="loading"
				:globalFilterFields="['publicKey']"
				class="col-span-4"
			>
				<template #header>
					<div class="mb-5 flex justify-between">
						<Search :filters-prop="filters" />
						<div class="flex">
							<button
								class="mr-2 flex items-center rounded bg-bright-orange px-2 py-1 font-bold text-dark-orange md:mb-0"
								@click="emit('add')"
							>
								<span> Add keys </span>
							</button>
						</div>
					</div>
				</template>

				<Column
					field="publicKey"
					header="Label"
					sortable
				>
					<template #body="{ data }">
						<p class="">{{ label(data.publicKey) }}</p>
					</template>
				</Column>

				<Column
					field="publicKey"
					header="Public Key"
					sortable
				>
					<template #body="{ data }">
						<div class="group relative">
							<!-- Public Key -->
							<p
								class="w-[50rem] overflow-hidden text-ellipsis whitespace-nowrap"
							>
								{{ cleanKey(data.publicKey) }}
							</p>

							<!-- Tooltip -->
							<div
								class="invisible absolute right-0 top-full z-50 mt-1 w-[50rem] break-all rounded bg-bright-orange px-2 py-1 text-left text-xs group-hover:visible"
							>
								{{ data.publicKey }}
							</div>
						</div>
					</template>
				</Column>

				<Column
					field="publicKey"
					header="Actions"
				>
					<template #body="{ data }">
						<div class="flex h-24 w-full items-center justify-center">
							<button
								:title="'Delete'"
								class="button icon-button button-error"
								@click="$emit('delete', data)"
							>
								<i class="material-icons">delete</i>
							</button>
						</div>
					</template>
				</Column>

				<template #empty>No keys found</template>
				<template #loading>
					<Loading typeOfData="Keys" />
				</template>
			</DataTable>
		</div>
	</div>
</template>
