import { useAuth } from "../auth";
import { Api } from "../portal-services-api-models";

// get token
const { cachedIdToken } = useAuth();

const portalServicesHttpClient = new Api({
    baseApiParams: { secure: true },
    securityWorker: async () => {
        return {
            headers: { Authorization: `Bearer ${cachedIdToken.value}` },
        };
    },
});
export const initPortalServicesClient = (defaultUrl: string) => {
    portalServicesHttpClient.baseUrl = `${defaultUrl}`;
};

export const usePortalServicesHttp = (): Api<unknown> =>
    portalServicesHttpClient as Api<unknown>;
