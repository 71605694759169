/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "jcaportalweb",
            "endpoint": "https://u8cthupes6.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:de1ccb98-b6f2-4ce7-9934-bdb7e44c7b7d",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_uqY5SH22L",
    "aws_user_pools_web_client_id": "4k3luj0g9h4hqmb17i2v5ssr1h",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "jcaportalweb-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d1ssuldx2dd87n.cloudfront.net",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "AuditTrails-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "PublicKeys-dev",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
